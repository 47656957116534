import * as React from "react";
import { FC, useState } from "react";
import Layout from "../components/layout/Layout";
import { Input, PhoneInputWrapper, TextArea } from "../components/ui/Forms";
import { useForm } from "react-hook-form";
import Button from "../components/ui/Button";
import t from "../i18n";
import HeadMeta from "../components/layout/HeadMeta";
import { sendMail } from "../services/actions";
import { Translatable, TypeLocale } from "../types";
import { PageProps } from "gatsby";


const DefaultValues = {
    firstName         : "",
    lastName          : "",
    email             : "",
    phone             : "+30",
    recaptcha_response: "",
    service           : "",
    message           : ""
}

type TypeContactDetails = {
    firstName: string
    lastName: string
    email: string
    phone: string
    recaptcha_response: string
    service?: string | number
    message: string
    general?: string
}

type ContactData = {}
type ContactContext = {
    locale: TypeLocale
}

const Contact: FC<PageProps<ContactData, ContactContext>> = ( { pageContext } ) => {
    const { locale: l } = pageContext
    const [ success, setSuccess ] = useState<boolean>( false );
    const [ isSubmitting, setIsSubmitting ] = useState<boolean>( false );
    const { register, handleSubmit, control, clearErrors, setValue, reset, setError, getValues, formState: { errors } } = useForm<TypeContactDetails>()

    const onSubmit = handleSubmit( async () => {
        setIsSubmitting( true )
        clearErrors( "general" );
        const token = process.env.GATSBY_GOOGLE_RECAPTACH_SITE_KEY && window.grecaptcha.execute( process.env.GATSBY_GOOGLE_RECAPTACH_SITE_KEY, { action: "submit" } );
        let result = await token;
        setValue( "recaptcha_response", result ?? "" )
        const values = getValues()
        if (process.env.NODE_ENV === 'development') {
            console.log( values )
            reset( DefaultValues )
            setIsSubmitting( false )
            return
        }
        sendMail( "https://mail.martha-sailing.com/contact/index.php", values,
            () => {
                setSuccess( true )
                reset( DefaultValues )
                setIsSubmitting( false )
            },
            ( error ) => {
                setError( "general", {
                    type   : "server-error",
                    message: error?.response?.data?.message
                } );
                setIsSubmitting( false )
            }
        );
    } )

    const successMessage: Translatable = {
        en: "Thank you for your message! We will get back to you as soon as possible.",
        el: "Ευχαριστούμε για το μήνυμά σας! Θα επικοινωνήσουμε μαζί σας το συντομότερο δυνατό."
    }

    return (
        <Layout>
            <h1 className={ "mb--md mx--xl" }>{ t( "Contact" ) }</h1>
            <HeadMeta title={ t( "Contact" ) }/>
            <form className={ "bg--bg p--xl round elevation--2" } onSubmit={ onSubmit }>
                <div className={ "lg:flex" }>
                    <Input label={ "First Name" } wrapperClassName={ "lg:mr--md" } errors={ errors } { ...register( "firstName" ) } />
                    <Input label={ "Last Name" } errors={ errors } { ...register( "lastName" ) } />
                </div>
                <Input label={ "Email" } errors={ errors } { ...register( "email" ) }/>
                <PhoneInputWrapper name={ "phone" } label={ "Phone" } errors={ errors } control={ control }/>
                <TextArea label={ "Message" } errors={ errors } { ...register( "message" ) } rows={ 6 }/>
                <input hidden type="text" { ...register( "recaptcha_response" ) } />
                { errors.general && <p className={ "bg--bg p--md brd border--danger mb--md" }>{ errors.general.message }</p> }
                { success && <p className={ "bg--bgDimmed p--md border border--emerald mb--md" }>{ successMessage[l] }</p> }
                { l === "en" ?
                    <p className={ "text--textDimmed mt--sm mb--0 small" }>
                        This site is protected by reCAPTCHA and the Google <a target={ "_blank" } rel={ "noopener noreferrer" } href="https://policies.google.com/privacy">Privacy Policy</a> and <a target={ "_blank" } rel={ "noopener noreferrer" } href="https://policies.google.com/terms">Terms of Service</a> apply.
                    </p>
                    :
                    <p className={ "text--textDimmed mt--sm mb--0 small" }>
                        Αυτό το προστατεύεται από reCAPTCHA και την <a target={ "_blank" } rel={ "noopener noreferrer" } href="https://policies.google.com/privacy?hl=el">Πολιτική Απορρήτου</a> και τους <a target={ "_blank" } rel={ "noopener noreferrer" } href="https://policies.google.com/terms?hl=el">Όρους Παροχής Υπηρεσιών</a> της Google.
                    </p>
                }
                <Button disabled={ isSubmitting } className={ "mt--sm" }>{ t( "Send" ) }</Button>
            </form>
        </Layout>
    )
}

export default Contact
