import axios from "axios";

export const sendMail = ( url: string, data: object, successCallback: (response: any) => void, errorCallback: (error: any) => void ) => {
    const headers = {
        headers: {
            "Content-Type": "application/json"
        }
    };
    axios
        .post( url, data, headers )
        .then( function ( response ) {
            if (response.data) {
                successCallback( response );
            }
        } )
        .catch( function ( error ) {
            if (error) {
                errorCallback( error );
            }
        } );
};
